import React, {useState, useEffect, useContext} from "react";
import {
    Grid,
    LinearProgress,
    Button,
    ButtonGroup,
    Dialog as MUIDialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@material-ui/core";
import {Markup} from "interweave";
import moment from 'moment';
import {FilterList} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {LocalizationContext} from '../../context/localizationContext';
import {ActivitiesLogsContext} from '../../context/activitieslogsContext';
import {AuthContext} from '../../context/authContext';
import Table from '../Table/index';
import Dialog from '../SeachDialog';
import SearchChips from '../SearchChips';

const useStyles = makeStyles(theme => ({
    pageHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
    },
    searchIcon: {
        height: 24,
        width: 24,
        cursor: 'pointer',
    }
}));

const ActivitiesLogsTable = props => {
    const {loading, setLoading} = useContext(AuthContext);
    const {getTranslation, lang} = useContext(LocalizationContext);
    const {getActivitiesLogs, activitiesFilters, setActivitiesFilters} = useContext(ActivitiesLogsContext);
    moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');
    const classes = useStyles();
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(activitiesFilters.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(activitiesFilters.rowsPerPage || 20);
    const [sort, setSort] = useState(activitiesFilters.sort || {column: 'log_date', by: 'asc'});
    const [searchState, setSearchState] = useState(false);
    const [filter, setFilter] = useState(activitiesFilters.filter || {});
    const [data, setData] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleChangePage = (event, newPage) => {
        setActivitiesFilters({...activitiesFilters, page: newPage});
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setActivitiesFilters({...activitiesFilters, page: 0, rowsPerPage: parseInt(event.target.value, 10)});
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortChange = (column, order) => {
        setActivitiesFilters({...activitiesFilters, sort: {column: column, by: order}});
        setSort({column: column, by: order});
    };

    const columns = [
        // user name
        {
            name: getTranslation('activities_log_list_table_header_user_name'),
            functionalName: 'user_name',
            type: 'text',
            filter: true,
            filterOptions: {
                col: 6,
            },
            sortable: true,
            visible: true,
            align: 'left',
        },
        // type
        {
            name: getTranslation('activities_log_list_table_header_type'),
            functionalName: 'type',
            type: 'text',
            filter: true,
            filterOptions: {
                col: 6,
            },
            sortable: true,
            visible: true,
            align: 'left',
        },
        // context
        {
            name: getTranslation('activities_log_list_table_header_context'),
            functionalName: 'context_temp',
            type: 'text',
            filter: true,
            filterOptions: {
                col: 6,
            },
            style: {width: 150},
            sortable: false,
            visible: true,
            align: 'left',
            format: (value) => {
                const contextObj = JSON.parse(value);
                const firstKey = Object.keys(contextObj)[0];
                return `${firstKey}: ${contextObj[firstKey]}`;
            }
        },
        // log date
        {
            name: getTranslation('activities_log_list_table_header_log_date'),
            functionalName: 'log_date',
            type: 'date',
            filter: true,
            filterOptions: {
                col: 6,
            },
            sortable: true,
            visible: true,
            align: 'left',
        },
    ];

    const handleRowClick = (rowData) => {
        setSelectedRow(rowData);
        setShowDialog(true);
    };

    const generateQueryParams = () => {
        const params = {
            order_by: `${sort.column} ${sort.by}`,
            page: page + 1, // assuming page is 0-indexed in the state
            per_page: rowsPerPage,
            ...filter,
        };
        return params;
    };

    const recursiveJsonParse = (context) => {
        for (let key in context) {
            if (context.hasOwnProperty(key)) {
                if (typeof context[key] !== "object" || context[key] === null) {
                    return {key: key, context: context[key]};
                } else {
                    let result = recursiveJsonParse(context[key]);
                    if (result) {
                        return result;
                    }
                }
            }
        }
    };

    const fetchData = () => {
        setLoading(true);
        getActivitiesLogs(generateQueryParams())
            .then(res => {
                if (res) {
                    let _data = [];
                    res.data.forEach(item => {
                        item.log_date = moment.unix(item.log_date).format('YYYY-MM-DD HH:mm:ss');
                        item.code = item.code === "en_US" ? getTranslation('generic_english_string') : getTranslation('generic_sweedish_string');

                        try {
                            const contextData = JSON.parse(item.context);
                            const test = recursiveJsonParse(contextData)
                            item.context_temp = `${test.key}: ${test.context}`;
                            item.contextData = contextData;
                        } catch (e) {
                            item.context_temp = getTranslation("logs_invalid_context_text");
                            item.contextData = getTranslation("logs_invalid_context_text");
                        }
                        _data.push(item);
                    })
                    setData(_data);
                    setCount(Number(res.total_records) || 0);
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };
    const clearFilter = () => {
        setFilter({});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchData()
    }, [filter, sort, page, rowsPerPage]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (page > 0) {
            setPage(0);
        }
        setActivitiesFilters({...activitiesFilters, filter: filter})
    }, [filter]);

    const parseContext = (context, result = "") => {
        const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value);

        for (let key in context) {
            if (context.hasOwnProperty(key)) {
                if (isObject(context[key])) {
                    result += `<p style="color: rgba(0,0,0,0.75)"><strong>${key}:</strong></p>`;

                    result = parseContext(context[key], result);
                } else {
                    result += `<p><strong>${key}: </strong>${context[key]}</p>`
                }
            }
        }
        return result;
    };

    const renderContextInfo = () => {
        if (selectedRow) {
            const data = selectedRow.contextData;

            if (data === getTranslation("logs_invalid_context_text")) {
                return getTranslation("logs_invalid_context_text");
            }

            return parseContext(data);
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ButtonGroup size="small" color="primary" className={classes.pageHeader}>
                    <Button onClick={clearFilter}>{getTranslation('generic_clear_filter')}</Button>
                    <Button startIcon={<FilterList/>}
                            onClick={() => setSearchState(true)}>{getTranslation('generic_button_filter')}</Button>
                </ButtonGroup>
                {Object.keys(filter).length > 0 &&
                    <SearchChips
                        filter={filter}
                        setFilter={setFilter}
                        columns={columns}/>
                }
                {loading && <LinearProgress/>}
                <Table
                    rows={data}
                    page={page}
                    count={count}
                    columns={columns}
                    handleChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleSortChange={handleSortChange}
                    sort={sort}
                    onRowClick={handleRowClick} // Implement row click
                    forcePointer
                />
                <Dialog
                    filter={filter}
                    setFilter={setFilter}
                    open={searchState}
                    onClose={() => setSearchState(false)}
                    columns={columns}
                />
                <MUIDialog open={showDialog} onClose={() => setShowDialog(false)}>
                    <DialogTitle>{getTranslation("logs_table_modal_title")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Markup content={renderContextInfo()}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowDialog(false)} color="primary">
                            {getTranslation("generic_button_ok")}
                        </Button>
                    </DialogActions>
                </MUIDialog>
            </Grid>
        </Grid>
    );
};

export default ActivitiesLogsTable;
